import {sheetId, sheetValuesRange} from "../../env"
import { fetchGoogleSheetValues } from "./requests";
import { Event } from "./model/event";
import moment from "moment/moment";

const NAME_COL_INDEX = 0;
const PRONOUNS_COL_INDEX = 1;
const BLURB_COL_INDEX = 2;
const START_TIME_COL_INDEX = 3;
const END_TIME_COL_INDEX = 4;
const IMAGE_LINK_COL_INDEX = 5;
const TWITCH_LINK_COL_INDEX = 6;

class GoogleSheetEventBroker {

    async getEvents() : Promise<Array<Event>> {
        const events:Array<Event> = [];

        const response = await fetchGoogleSheetValues(sheetId, sheetValuesRange);
        if (response.status === 403)
            throw new Error("Unauthorized response from API, ensure sheet is publicly available and the Access Key " +
                    "is configured to allow requests with a referrer from this host address.");

        const responseContent = await response.json();
        const rows:Array<Array<string>> = responseContent.values;
        for (let row of rows) {
            const startDate = moment.parseZone(row[START_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const endDate = moment.parseZone(row[END_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const event = new Event(
                row[NAME_COL_INDEX],
                row[PRONOUNS_COL_INDEX],
                row[BLURB_COL_INDEX],
                startDate,
                endDate,
                row[IMAGE_LINK_COL_INDEX],
                row[TWITCH_LINK_COL_INDEX]);
            events.push(event);
        }
        return events;
    }

}

const googleSheetEventBroker = new GoogleSheetEventBroker();
export { googleSheetEventBroker };
