import { eventBroker, Event } from "../../services/events";
import {useState, useEffect } from "react"
import { EventItem } from "../../components/eventItem/eventItem";
import "./schedule.css";

export const Schedule = () => {
    const [ events, setEvents ] = useState([] as Array<Event>);

    useEffect(() => {
        async function getEvents() {
            const events = await eventBroker.getEvents();
            setEvents(events);
        }
        getEvents().then(_ => {
            setInterval(getEvents, 5000);
        });
    }, [])

    if (events.length === 0)
        return <>Loading...</>;

    const now = new Date();
    const current = events.find(e => e.startTime.isBefore(now) && e.endTime.isAfter(now));
    const upNext = events.filter(e => e.startTime.isSameOrAfter(now)).splice(0, current ? 2 : 3);

    return <>
        <div className="view-schedule__container">
            { current &&
            <div className="view-schedule__events view-schedule__current">
                <EventItem event={current} key={current.name + current.startTime.toJSON()} />
            </div>}

            { upNext.length > 0 &&
            <div className="view-schedule__events view-schedule__upNext">
                {upNext.map((event, i) =>
                    <EventItem event={event} key={i + event.name + event.startTime.toJSON()} />
                )}
            </div> }
        </div>
    </>;
}