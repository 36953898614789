import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Default} from "../views/default/default";
import {Schedule} from "../views/schedule/schedule";
import "./app.css";

export const App = () =>
    <BrowserRouter>
        <Routes>
            <Route index element={<Default />} />
            <Route path="schedule" element={<Schedule />} />
        </Routes>
    </BrowserRouter>
