import React  from "react";
import { Event } from "../../services/events";
import "./eventItem.css";

export interface EventItemProps {
    event: Event;
}

export const EventItem = (props: EventItemProps) => {
    const startTime = props.event.startTime;
    const formattedTime =
        `${startTime.format("h:mm a")} est`;
    const twitchLink = `https://twitch.tv/${props.event.twitch || ""}`;

    return <div className="event-item__container">
            <p className="event-item__from">
                <span>
                    {formattedTime}
                </span>
            </p>
            <div className="event-item__image-container">
                { props.event.imageLink &&
                    <img className="event-item__image" src={props.event.imageLink} alt={`Logo for ${props.event.name}`}/> }
            </div>
            <div />
            <div className="event-item__content-container">
                <h3 className="event-item__name">
                    {props.event.name}
                </h3>
                <p className="event-item__pronouns">
                    {props.event.pronouns}
                </p>
                {props.event.twitch &&
                    <h4 className="event-item__twitch">
                        <i className="fa fa-twitch" />
                        <a href={twitchLink} target="_blank" rel="noreferrer">{props.event.twitch}</a>
                    </h4>
                }
                {props.event.blurb.split("\n").map(p => <p className="event-item__blurb">{p}</p>)}
            </div>
        </div>;
}
