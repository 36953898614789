import { Moment } from "moment";

export class Event {

    public name:string;
    public pronouns: string;
    public blurb:string;
    public startTime: Moment;
    public endTime:Moment;
    public imageLink:string;
    public twitch:string;

    constructor(name:string, pronouns: string, blurb:string, startTime:Moment, endTime:Moment, imageLink:string, twitch:string) {
        this.name = name;
        this.pronouns = pronouns;
        this.blurb = blurb;
        this.startTime = startTime;
        this.endTime = endTime;
        this.imageLink = imageLink;
        this.twitch = twitch;
    }

}
