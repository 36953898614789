const prefix = "REACT_APP_BGB_";

const sheetsApiUrl = process.env[`${prefix}SHEETS_API_URI`] as string;
const sheetId = process.env[`${prefix}SHEETS_SHEET_ID`] as string;
const sheetValuesRange = process.env[`${prefix}SHEETS_VALUES_RANGE`] as string;
const sheetsAccessKey = process.env[`${prefix}SHEETS_ACCESS_KEY`] as string;

let errors = "";
if (!sheetsApiUrl)
    errors += "Sheets Api Url is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_API_URI at compilation.\n";
if (!sheetId)
    errors += "Google Sheet Id is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_SHEET_ID at compilation.\n";
if (!sheetValuesRange)
    errors += "Google Sheet Values Range is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_VALUES_RANGE at compilation.\n";
if (!sheetsAccessKey)
    errors += "API Access Key is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_ACCESS_KEY at compilation.\n";

if (errors.length > 0)
    throw new Error(errors);

export { sheetsApiUrl, sheetId, sheetValuesRange, sheetsAccessKey }