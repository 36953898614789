import { eventBroker, Event } from "../../services/events";
import {useState, useEffect } from "react"
import { EventItem } from "../../components/eventItem/eventItem";
import ReactPlayer from 'react-player'
import logo from "./logo.png";
import "./default.css";

export const Default = () => {
    const [ events, setEvents ] = useState([] as Array<Event>);

    useEffect(() => {
        (async () => {
            const events = await eventBroker.getEvents();
            setEvents(events);
        })();
    }, [])

    if (events.length === 0)
        return <>Loading...</>;
    else
        return <>
            <div className="view-default__container">
                <h1>
                    <img className="view-default__header-logo" src={logo} alt="Burgundy Gives Back" />
                </h1>
                <h2 className="view-default__tag-line">A WINTER EXTRAVAGANZA BENEFITING TRANS LIFELINE!</h2>

                {  (window.location.toString().endsWith("?stream") || new Date() > new Date("2022-12-10T16:30:00.000Z")) &&
                    <div className="view-default__twitch-embed">
                        <ReactPlayer url='https://www.twitch.tv/lilyvelour' width="100%" height="100%"/>
                    </div>
                }

                <article className="view-default__intro">

                    <div className="view-default__blurb">
                        <p>
                            Join us for a 12-hour holiday celebration as <strong>Burgundy Gives Back</strong> to our community.
                            We’re proud to support <strong>Trans Lifeline*</strong> during this event, a trans-led organization
                            that connects trans people to the community, support, and resources they need to
                            survive and thrive.
                        </p>
                        <p>
                            Be a part of the fun and festivities at our venue in Final Fantasy XIV
                            or watch live on <strong>Twitch:&nbsp;
                                <a href="https://twitch.tv/lilyvelour">twitch.tv/lilyvelour</a>
                            </strong>!
                        </p>
                    </div>

                    <div className="view-default__details">
                        <p className="view-default__date">Saturday, December 10</p>
                        <p className="view-default__time">Starting at 12 PM through 12AM est</p>

                        <div className="view-default__location">
                            <div className="view-default__location-part">
                                <span className="view-default__location-label">Datacenter</span>
                                <span className="view-default__location-value">Aether</span>
                            </div>
                            <div className="view-default__location-part">
                                <span className="view-default__location-label">Server</span>
                                <span className="view-default__location-value">Gilgamesh</span>
                            </div>
                            <div className="view-default__location-part">
                                <span className="view-default__location-label">Address</span>
                                <span className="view-default__location-value">Goblet <span>W</span>2 <span>P</span>43</span>
                            </div>
                        </div>
                    </div>
                </article>

                <a className="view-default__button" rel="noreferrer" href="https://burgundygivesback.com/donate" target="_blank">Donate to Trans Lifeline!</a>

                <div className="view-default__events">
                    <h2>Schedule</h2>
                    {events.map((event, i) =>
                        <EventItem event={event} key={i} />
                    )}
                </div>

                <div className="view-default__disclaimer">
                    * While all donations will go to the charity, Burgundy Gives Back is not officially endorsed by Trans Lifeline.
                </div>
            </div>
        </>;
}